<template>
  <div class="card-img">
    <b-img
      fluid
      class="card-img__media"
      :alt="title"
      :src="src"
      :blank="!src"
      blank-color="#ccc"
      width="640"
      height="360"
    />
    <span class="card-img__title">{{ title }}</span>
    <div class="bullet-cta-left">
      <slot name="info" />
    </div>
    <div class="bullet-cta">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  }
}
</script>
