<template>
<b-card no-body>
  <template #header>
    <h5 class="card-title">Insurance Premium Payment</h5>
  </template>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VFormInfo"
  >
    <b-form @submit.prevent="handleSubmit(doSubmitInfo())">
      <b-card-body>
        <b-alert v-if="setting.needSubmit && parent.moduleRole('update_main_info')" variant="warning" show>
          <b>{{ parent.Config.wording.detectUpdate }}</b>
        </b-alert>
        <b-row>
          <b-col md="6">
            <b-form-group label-for="infoClaimTitleID">
              <template #label>Title (ID)<strong class="text-danger">*</strong></template>
              <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="infoClaimTitleID" v-model="setting.title_id" @input="setting.needSubmit=true" />
              <VValidate 
                name="Title ID" 
                v-model="setting.title_id" 
                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
              />
            </b-form-group>
            <b-form-group label-for="infoClaimDescID">
              <template #label>Description (ID)<strong class="text-danger">*</strong></template>
              <b-form-textarea @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="infoClaimDescID" rows="4" v-model="setting.desc_id" @input="setting.needSubmit=true" />
              <VValidate 
                name="Description ID" 
                v-model="setting.desc_id" 
                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label-for="infoClaimTitleEN">
              <template #label>Title (EN)<strong class="text-danger">*</strong></template>
              <b-form-input @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="infoClaimTitleEN" v-model="setting.title_en" @input="setting.needSubmit=true" />
              <VValidate 
                name="Title EN" 
                v-model="setting.title_en" 
                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
              />
            </b-form-group>
            <b-form-group label-for="infoClaimDescEN">
              <template #label>Description (EN)<strong class="text-danger">*</strong></template>
              <b-form-textarea @keyup="parent.removeWildChar" @keydown="parent.removeWildChar" id="infoClaimDescEN" rows="4" v-model="setting.desc_en" @input="setting.needSubmit=true" />
              <VValidate 
                name="Description EN" 
                v-model="setting.desc_en" 
                :rules="{required:1, min:3, regex:/^[^\s]+(\s+[^\s]+)*$/}"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer v-if="parent.moduleRole('update_main_info')">
        <div class="text-right">
          <b-button type="submit" class="btn-rounded" variant="info">Save Changes</b-button>
        </div>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import Gen from '@/libs/Gen'

export default{
  props:{ setting:Object },
  data(){
    return {
      input:{},
    }
  },
  computed:{
    parent(){
      return this.$parent.$parent.$parent
    },
  },
  methods:{
    doSubmitInfo(){
      this.$refs["VFormInfo"].validate().then(success => {
        if (!success) return
        let input = this.setting
        input.needSubmit=false
        
        this.parent.loadingOverlay = true 
        const data = JSON.parse(JSON.stringify(input))

        Gen.apiRest(
          "/do/"+this.parent.modulePage, 
          {data: Object.assign({}, data, {type:'update-main-info', id:input.id})}, 
          "POST"
        ).then(res=>{
            this.parent.loadingOverlay = false
            let resp = res.data

            this.$emit('update:setting', input)

            resp.statusType = 200
            this.parent.doSetAlertForm(resp)

            setTimeout(()=>{ this.message="" },15000)
        }).catch(err=>{
            this.parent.loadingOverlay = false
            
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response?.data?.message
                err.messageError = err.message
            }

            this.parent.doSetAlertForm(err)
        })
      })
    }
  }
}
</script>